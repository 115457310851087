import styles from './index.module.scss';
import panda from '../../../assets/error.png';
import { Link } from 'react-router-dom';

export const Footer = () => {
    return (
        <>
            <div className={styles.footer}>
                <div className={styles.footerTop}>
                    <div className={styles.box}>
                        <div className={styles.imgBox}>
                            <img src="https://modeai.oss-cn-hangzhou.aliyuncs.com/lgfbgzh.png" />
                        </div>
                        <div>关注公众号，获取更多论文技巧</div>
                    </div>
                    <div className={styles.box}>
                        <div className={styles.imgBox}>
                            <img src="https://modeai.oss-cn-hangzhou.aliyuncs.com/lgfbqq.png" />
                        </div>
                        <div>灵感风暴官方QQ群</div>
                    </div>
                </div>
            </div>
            <footer className="bg-white py-8">
                <div className="w-[78vw] mx-auto px-4">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div>
                            <h3 className="text-base text-[#192038] font-semibold mb-4">
                                关于我们
                            </h3>
                            <ul className="space-y-2">
                                <li>
                                    <Link
                                        to={'/companyIntro'}
                                        target={'_blank'}
                                        className="text-[#414C6F] text-sm hover:text-gray-900"
                                    >
                                        公司介绍
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <h3 className="text-base text-[#192038] font-semibold mb-4">
                                产品介绍
                            </h3>
                            <ul className="space-y-2">
                                <li>
                                    <Link
                                        to={'/productIntro'}
                                        target={'_blank'}
                                        className="text-[#414C6F] text-sm hover:text-gray-900"
                                    >
                                        产品介绍
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <h3 className="text-base text-[#192038] font-semibold mb-4">
                                联系我们
                            </h3>
                            <ul className="space-y-2">
                                <li className={'text-[#414C6F] text-sm '}>
                                    客服邮箱：
                                    <a href="kf@lingganfengbao.com" className="hover:text-gray-900">
                                        kf@lingganfengbao.com
                                    </a>
                                </li>
                                <li className={'text-[#414C6F] text-sm '}>
                                    投诉反馈：
                                    <a
                                        href="ts@lingganfengbao.com"
                                        className=" hover:text-gray-900"
                                    >
                                        ts@lingganfengbao.com
                                    </a>
                                </li>
                                <li className={'text-[#414C6F] text-sm '}>
                                    商务合作：
                                    <a href="bd@lingganfengbao.com" className="hover:text-gray-900">
                                        bd@lingganfengbao.com
                                    </a>
                                </li>
                                <li className={'text-[#414C6F] text-sm '}>
                                    联系地址：
                                    <span>
                                        安徽省合肥市蜀山区西园街道黄山路468号通和佳苑（易居时代公寓）1幢、2幢1＃905室
                                    </span>
                                </li>
                                <li className={'text-[#414C6F] text-sm '}>
                                    联系电话：
                                    <span>18391174426</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            <div className={'flex justify-center'}>
                <a
                    href="https://beian.miit.gov.cn/#/Integrated/index"
                    className={styles.aboutUs}
                    target="_blank"
                >
                    皖ICP备2023021108号-2 LINGGANFENGBAO.COM
                </a>
            </div>
        </>
    );
};

import './App.css';
import zhCN from 'antd/locale/zh_CN';
import { Documents } from './Article/Documents';
import { Example } from './Article/Example';

import { ConfigProvider } from 'antd';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './common/common.scss';
import { Article } from './Article';
import { useEffect, useMemo, useState } from 'react';
import { loginInstance } from './Service/login';
import { FloatButton, Popover } from 'antd';
import { CustomerServiceOutlined } from '@ant-design/icons';
import { Agreement } from './Article/Agreement';
import { legacyLogicalPropertiesTransformer, StyleProvider } from '@ant-design/cssinjs';
import { observer } from 'mobx-react';
import { summaryStore } from './Article/Documents/store';
import { ErrorBoundary } from './Article/ErrorBoundary/index';
import ProductIntro from './pages/productIntro/index';
import CompanyIntro from './pages/companyIntro/index';
import CssVariableSetter from './components/CssVariableSetter';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Article />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: '',
                element: <Documents />,
            },
            {
                path: 'example',
                element: <Example />,
            },
            {
                path: '/companyIntro',
                element: <CompanyIntro />,
                errorElement: <ErrorBoundary />,
            },
            {
                path: '/productIntro',
                element: <ProductIntro />,
                errorElement: <ErrorBoundary />,
            },
        ],
    },
    { path: '/agreement', element: <Agreement /> },
]);

const App = observer(() => {
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        setLoaded(true);
    }, []);

    const [open, setOpen] = useState(false);

    const hide = () => {
        setOpen(false);
    };

    const show = () => {
        if (!loginInstance.hasLogin) {
            loginInstance.open();
            return;
        }
        setOpen(true);
    };

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const theme = useMemo(() => {
        // Ultra 版本的主题
        if (summaryStore.useUltra) {
            return {
                cssVar: true,
                token: {
                    colorPrimary: '#f7941d',
                    colorInfoBg: '#fffbe6',
                    colorInfoActive: '#a277ee',
                    colorInfoText: '#a277ee',
                },
            };
        }
        // Normal 版本的主题
        return {
            cssVar: true,
            hashed: false,
            token: {
                colorPrimary: '#a277ee',
                colorInfoBg: '#fffbe6',
                colorInfoActive: '#a277ee',
                colorInfoText: '#a277ee',
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [summaryStore.useUltra]);

    console.log('theme', theme);

    return loaded ? (
        <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
            <ConfigProvider locale={zhCN} theme={theme}>
                <CssVariableSetter isGlobal>
                    <RouterProvider router={router} />
                    <Popover
                        title={
                            <div style={{ textAlign: 'center', fontSize: '16px' }}>联系客服</div>
                        }
                        placement="topRight"
                        content={
                            <img
                                src="https://modeai.oss-cn-hangzhou.aliyuncs.com/lgfbkf.png"
                                style={{ width: '180px', margin: '0 auto' }}
                            />
                        }
                    >
                        <FloatButton
                            description="联系客服"
                            icon={
                                <CustomerServiceOutlined
                                    style={{ fontSize: '24px', marginLeft: '-2px' }}
                                />
                            }
                            type="primary"
                            style={{ right: 24, width: '60px', height: '60px', bottom: '70px' }}
                            shape="square"
                        />
                    </Popover>
                </CssVariableSetter>
            </ConfigProvider>
        </StyleProvider>
    ) : (
        ''
    );
});

export default App;

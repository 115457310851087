import useCssVariables from '../hooks/useCssVariables';

const CssVariableSetter = ({ isGlobal, children }) => {
    // 执行自定义hook进行CSS变量的生成与写入，并获取此层级对应的容器id
    // 本次生成的CSS变量只会影响该容器下传入的children中的元素样式
    const { cssVarContainerID } = useCssVariables(isGlobal);
    // 如果声明此次同步是全局生效的，则可以不需要生成一个实际的容器div，用fragment包裹即可
    if (isGlobal) {
        return <>{children}</>;
    }

    return (
        <div
            ref={(el) => {
                if (el) {
                    el.id = cssVarContainerID;
                }
            }}
        >
            {children}
        </div>
    );
};
export default CssVariableSetter;

import { useState, useEffect } from 'react';

/**
 * 设备信息检测 hook
 * @returns {Object} deviceInfo - 设备信息对象
 * @returns {boolean} deviceInfo.isMobile - 是否移动设备
 * @returns {boolean} deviceInfo.isTablet - 是否平板设备
 * @returns {boolean} deviceInfo.isDesktop - 是否桌面设备
 * @returns {boolean} deviceInfo.isPortrait - 是否竖屏
 * @returns {boolean} deviceInfo.isLandscape - 是否横屏
 * @returns {number} deviceInfo.screenWidth - 屏幕宽度
 * @returns {number} deviceInfo.screenHeight - 屏幕高度
 * @returns {string} deviceInfo.deviceType - 设备类型(mobile/tablet/desktop)
 * @returns {string} deviceInfo.orientation - 屏幕方向(portrait/landscape)
 * @returns {boolean} deviceInfo.isTouchDevice - 是否触摸设备
 */
export const useDevice = () => {
    // 检测是否支持触摸
    const isTouchDevice = () => {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    };

    // 获取设备信息
    const getDeviceInfo = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;

        // 基于屏幕宽度判断设备类型
        const isMobile = width < 480; // 手机
        const isTablet = width >= 480 && width < 1024; // 平板设备
        const isDesktop = width >= 1024; // 桌面设备

        return {
            isMobile,
            isTablet,
            isDesktop,
            isPortrait: height > width,
            isLandscape: width > height,
            screenWidth: width,
            screenHeight: height,
            deviceType: isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop',
            orientation: height > width ? 'portrait' : 'landscape',
            isTouchDevice: isTouchDevice(),
        };
    };

    const [deviceInfo, setDeviceInfo] = useState(getDeviceInfo());

    useEffect(() => {
        let timeoutId;

        // 处理屏幕尺寸变化,使用防抖优化性能
        const handleResize = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setDeviceInfo(getDeviceInfo());
            }, 100); // 100ms防抖
        };

        // 监听屏幕尺寸和方向变化
        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleResize);

        // 组件卸载时清理监听器和定时器
        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    return deviceInfo;
};

import { Collapse, Modal, Input, Radio, Alert, message, Select } from 'antd';
import cx from 'classnames';
import styles from './index.module.scss';
import { summaryStore } from '../store';
import { Operator } from './Operator';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { CalculatorOutlined } from '@ant-design/icons';
import { CodeIcon, GongshiIcon, PicIcon, TableIcon } from './icons';

export const Summary = observer((props) => {
    const [modalInfo, setModal] = useState({
        show: false,
        // 弹窗类型，add新增，edit编辑
        type: 'add',
        isAfter: true,
        inputMain: '',
        inputSub: '',
        // 标题级别，从1开始，表示1级标题，最多3级
        level: 1,
        level1idx: 0,
        level2idx: 0,
        level3idx: 0,
    });

    const handleSubmitModal = useCallback(() => {
        const {
            type,
            level,
            inputMain,
            inputSub,
            insertType,
            selectedSubChapter,
            sections,
            subsections,
        } = modalInfo;
        if (inputMain.trim() === '') {
            message.error('请输入章节标题');
            return;
        }
        // if (level === 3 && inputSub.trim() === '') {
        //     message.error('请输入章节描述');
        //     return;
        // }
        // 选择插入子章节时的校验
        if (type === 'add' && insertType === 'sub') {
            // 有子章节时必须选择插入位置
            if (level === 1 && sections?.length > 0 && selectedSubChapter === null) {
                message.error('请选择要插入的章节位置');
                return;
            }
            if (level === 2 && subsections?.length > 0 && selectedSubChapter === null) {
                message.error('请选择要插入的章节位置');
                return;
            }

            // 检查层级限制
            if (level === 3) {
                message.error('三级章节不能再插入子章节');
                return;
            }
        }
        if (type === 'add') {
            summaryStore.addTitle({ ...modalInfo });
        } else if (type === 'edit') {
            summaryStore.editTitle({ ...modalInfo });
        }
        setModal((m) => ({
            ...m,
            show: false,
        }));
    }, [modalInfo]);

    const handleDelete = useCallback((opts) => {
        summaryStore.deleteTitle(opts);
    }, []);

    const SummaryHeader = useCallback(
        ({ it, idx }) => {
            return (
                <div className={styles.summaryHeader}>
                    <div>{it.chapter}</div>
                    <Operator
                        data={it}
                        onAdd={() =>
                            setModal({
                                show: true,
                                type: 'add',
                                isAfter: true,
                                inputMain: '',
                                inputSub: '',
                                level: 1,
                                level1idx: idx,
                                level2idx: 0,
                                level3idx: 0,
                                insertType: 'same',
                                selectedSubChapter: null,
                                // 设置节数据
                                sections: it.sections || [],
                            })
                        }
                        onEdit={() =>
                            setModal({
                                show: true,
                                type: 'edit',
                                isAfter: true,
                                inputMain: it.chapter,
                                inputSub: it.chapterAbstract,
                                level: 1,
                                level1idx: idx,
                                level2idx: 0,
                                level3idx: 0,
                            })
                        }
                        onDelete={() =>
                            handleDelete({
                                level: 1,
                                level1idx: idx,
                                level2idx: 0,
                                level3idx: 0,
                                txt: it.chapter,
                            })
                        }
                    />
                </div>
            );
        },
        [handleDelete],
    );

    const SectionHeader = useCallback(
        ({ s, idx, sidx, isLastLevel }) => {
            return (
                <div className={styles.cSectionHeader}>
                    <div className={styles.cSectionTitle}>
                        <div className={styles.title}>
                            {/* <span>
                {idx + 1}.{sidx + 1}
              </span> */}
                            <div>{s.chapter}</div>
                        </div>
                        <div className={styles.desc}>{s.chapterAbstract}</div>
                    </div>
                    <Operator
                        data={s}
                        className={styles.opt}
                        isLastLevel={isLastLevel}
                        onAdd={() =>
                            setModal({
                                show: true,
                                type: 'add',
                                isAfter: true,
                                inputMain: '',
                                inputSub: '',
                                level: 2,
                                level1idx: idx,
                                level2idx: sidx,
                                level3idx: 0,
                                insertType: 'same',
                                selectedSubChapter: null,
                                // 设置小节数据
                                subsections: s.subsections || [],
                            })
                        }
                        onEdit={() =>
                            setModal({
                                show: true,
                                type: 'edit',
                                isAfter: true,
                                inputMain: s.chapter,
                                inputSub: s.chapterAbstract,
                                level: 2,
                                level1idx: idx,
                                level2idx: sidx,
                                level3idx: 0,
                            })
                        }
                        onDelete={() =>
                            handleDelete({
                                level: 2,
                                level1idx: idx,
                                level2idx: sidx,
                                level3idx: 0,
                                txt: s.chapter,
                            })
                        }
                        onChangeExtra={(v) => {
                            summaryStore.changeTitleExtra({
                                v,
                                level: 2,
                                level1idx: idx,
                                level2idx: sidx,
                                level3idx: 0,
                            });
                        }}
                    />
                </div>
            );
        },
        [handleDelete],
    );

    const [top, setTop] = useState(35);

    const onScroll = useMemo(
        () =>
            debounce(() => {
                const el = document.getElementById('summaryEl');
                if (!el) return;
                const maxH = el ? el.offsetHeight - 200 : 1000;
                const distance = document.scrollingElement.scrollTop - el.offsetTop + 60;
                setTop(Math.min(Math.max(35, distance), maxH));
            }, 500),
        [],
    );

    useEffect(() => {
        document.addEventListener('scroll', onScroll);
        return () => {
            document.removeEventListener('scroll', onScroll);
        };
    }, []);

    // 检查是否有子章节
    const hasSubChapters = (modalInfo) => {
        if (modalInfo.level === 1) {
            return modalInfo.sections?.length > 0;
        }
        if (modalInfo.level === 2) {
            return modalInfo.subsections?.length > 0;
        }
        return false;
    };

    // 获取子章节列表
    const getSubChapters = (modalInfo) => {
        if (modalInfo.level === 1) {
            return modalInfo.sections || [];
        }
        if (modalInfo.level === 2) {
            return modalInfo.subsections || [];
        }
        return [];
    };

    return (
        <div className={cx([styles.summaryWrapper, styles[props.from]])} id="summaryEl">
            <div className={styles.summaryBox}>
                <Collapse ghost defaultActiveKey={summaryStore.paragraphs.map((_, idx) => idx)}>
                    {summaryStore.paragraphs.map((it, idx) => {
                        return (
                            <Collapse.Panel key={idx} header={<SummaryHeader it={it} idx={idx} />}>
                                {it.sections?.map((s, sidx) => {
                                    const haveThreeOutline =
                                        s.subsections && s.subsections.length > 0;
                                    return haveThreeOutline ? (
                                        <Collapse
                                            ghost
                                            key={sidx}
                                            className={styles.collaspseSection}
                                            defaultActiveKey={[sidx]}
                                        >
                                            <Collapse.Panel
                                                key={sidx}
                                                header={
                                                    <SectionHeader s={s} idx={idx} sidx={sidx} />
                                                }
                                            >
                                                {s.subsections.map((sub, subIdx) => (
                                                    <div className={styles.subSection} key={subIdx}>
                                                        <div>
                                                            <div className={styles.subSectionTitle}>
                                                                {/* {idx + 1}.{sidx + 1}.{subIdx + 1} */}
                                                                {sub.chapter}
                                                            </div>
                                                            <div className={styles.subSectionAbs}>
                                                                {sub.chapterAbstract}
                                                            </div>
                                                        </div>
                                                        <Operator
                                                            data={sub}
                                                            className={styles.opt}
                                                            isLastLevel={true}
                                                            onAdd={() =>
                                                                setModal({
                                                                    show: true,
                                                                    type: 'add',
                                                                    isAfter: true,
                                                                    inputMain: '',
                                                                    inputSub: '',
                                                                    insertType: 'same',
                                                                    level: 3,
                                                                    level1idx: idx,
                                                                    level2idx: sidx,
                                                                    level3idx: subIdx,
                                                                })
                                                            }
                                                            onEdit={() =>
                                                                setModal({
                                                                    show: true,
                                                                    type: 'edit',
                                                                    isAfter: true,
                                                                    inputMain: sub.chapter,
                                                                    inputSub: sub.chapterAbstract,
                                                                    level: 3,
                                                                    level1idx: idx,
                                                                    level2idx: sidx,
                                                                    level3idx: subIdx,
                                                                })
                                                            }
                                                            onDelete={() =>
                                                                handleDelete({
                                                                    level: 3,
                                                                    level1idx: idx,
                                                                    level2idx: sidx,
                                                                    level3idx: subIdx,
                                                                    txt: sub.chapter,
                                                                })
                                                            }
                                                            onChangeExtra={(v) => {
                                                                summaryStore.changeTitleExtra({
                                                                    v,
                                                                    level: 3,
                                                                    level1idx: idx,
                                                                    level2idx: sidx,
                                                                    level3idx: subIdx,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                ))}
                                            </Collapse.Panel>
                                        </Collapse>
                                    ) : (
                                        <div
                                            className={classNames(
                                                styles.collaspseSection,
                                                styles.collaspseSectionSingle,
                                            )}
                                            key={sidx}
                                        >
                                            <SectionHeader
                                                isLastLevel={true}
                                                s={s}
                                                idx={idx}
                                                sidx={sidx}
                                            />
                                        </div>
                                    );
                                })}
                            </Collapse.Panel>
                        );
                    })}
                </Collapse>
            </div>
            {props.from !== 'drawer' && (
                <div className={styles.summaryTips} style={{ top: top + 'px' }}>
                    <div className={styles.bold}>TIPS：</div>
                    <div>👈点亮右侧按钮</div>
                    <div className={styles.line}>
                        {TableIcon}
                        <div>
                            ：插入<span>数据表</span>
                        </div>
                    </div>
                    <div className={styles.line}>
                        {PicIcon}
                        <div>
                            ：插入<span>流程图</span>
                        </div>
                    </div>
                    <div className={styles.line}>
                        {GongshiIcon}
                        <div>
                            ：插入<span>公式</span>
                        </div>
                    </div>

                    <div className={styles.line}>
                        {CodeIcon}
                        <div>
                            ：插入<span>代码段</span>
                        </div>
                    </div>
                    <div className={styles.line}>
                        支持<span>增</span>、<span>删</span>、<span>改</span>
                    </div>
                    {summaryStore._tempAutoText && (
                        <div style={{ lineHeight: '20px' }}>
                            <span>
                                小灵会自动根据您的需要点亮相应的{summaryStore._tempAutoText}
                            </span>
                            <br />
                            您可根据需要进行<span>添加或删减</span>
                        </div>
                    )}
                </div>
            )}

            <Modal
                open={modalInfo.show}
                title={modalInfo.type === 'add' ? '新增章节' : '编辑章节'}
                onOk={handleSubmitModal}
                onCancel={() => {
                    setModal((m) => ({
                        ...m,
                        show: false,
                    }));
                }}
            >
                <Alert
                    message="全文生成效果受章节数和概要内容影响，请谨慎修改"
                    type="warning"
                    showIcon
                />
                {/* 插入位置 */}
                {modalInfo.type === 'add' && (
                    <>
                        <div className={styles.label}>插入位置</div>
                        {/* 根据不同层级显示不同选项 */}
                        <div>
                            <Radio.Group
                                optionType="button"
                                buttonStyle="solid"
                                value={modalInfo.insertType}
                                className="mb-4"
                                onChange={(e) =>
                                    setModal((p) => ({
                                        ...p,
                                        insertType: e.target.value,
                                        selectedSubChapter: null,
                                        isAfter: true,
                                    }))
                                }
                            >
                                <Radio.Button value="same">插入本章</Radio.Button>
                                {/* 只在1级和2级章节显示插入下级选项 */}
                                {modalInfo.level < 3 && (
                                    <Radio.Button value="sub">插入子级章节</Radio.Button>
                                )}
                            </Radio.Group>
                        </div>
                        <div>
                            {/* 插入同级位置选择 */}
                            {modalInfo.insertType === 'same' && (
                                <>
                                    <div className={styles.label}>插入位置</div>
                                    <Radio.Group
                                        optionType="button"
                                        buttonStyle="solid"
                                        value={modalInfo.isAfter}
                                        onChange={(e) =>
                                            setModal((p) => ({
                                                ...p,
                                                isAfter: e.target.value,
                                            }))
                                        }
                                    >
                                        <Radio.Button value={false}>本章之前</Radio.Button>
                                        <Radio.Button value={true}>本章之后</Radio.Button>
                                    </Radio.Group>
                                </>
                            )}
                        </div>
                        <div>
                            {/* 如果选择插入下级且存在下级章节，显示下级章节选择 */}
                            {modalInfo.insertType === 'sub' && hasSubChapters(modalInfo) && (
                                <>
                                    <div className={styles.label}>
                                        选择子章节<b>*</b>
                                    </div>
                                    <Select
                                        style={{
                                            width: '100%',
                                            marginBottom: '16px',
                                        }}
                                        placeholder={`请选择子章节`}
                                        value={modalInfo.selectedSubChapter}
                                        onChange={(value) =>
                                            setModal((p) => ({
                                                ...p,
                                                selectedSubChapter: value,
                                            }))
                                        }
                                    >
                                        {getSubChapters(modalInfo).map((chapter, index) => (
                                            <Select.Option key={index} value={index}>
                                                {chapter.chapter}
                                            </Select.Option>
                                        ))}
                                    </Select>

                                    {/* 选择了下级章节后显示插入位置 */}
                                    {modalInfo.selectedSubChapter !== null && (
                                        <>
                                            <Radio.Group
                                                optionType="button"
                                                buttonStyle="solid"
                                                value={modalInfo.isAfter}
                                                onChange={(e) =>
                                                    setModal((p) => ({
                                                        ...p,
                                                        isAfter: e.target.value,
                                                    }))
                                                }
                                            >
                                                <Radio.Button value={false}>章节之前</Radio.Button>
                                                <Radio.Button value={true}>章节之后</Radio.Button>
                                            </Radio.Group>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </>
                )}
                {/* 章节标题 */}
                <div className={styles.label}>
                    插入章节标题<b>*</b>
                </div>
                <Input
                    placeholder={`插入章节标题`}
                    value={modalInfo.inputMain}
                    onChange={(e) => setModal((p) => ({ ...p, inputMain: e.target.value }))}
                />
                {/* 章节概要 */}
                {modalInfo.level > 2 ||
                (modalInfo.level === 2 && !summaryStore.useThreeOutline) ||
                (modalInfo.type === 'edit' &&
                    modalInfo.inputSub !== null &&
                    modalInfo.inputSub !== undefined) ? (
                    <>
                        <div className={styles.label}>
                            章节概要<b>*</b>
                        </div>
                        <Input.TextArea
                            placeholder="请输入章节概要"
                            value={modalInfo.inputSub}
                            onChange={(e) => setModal((p) => ({ ...p, inputSub: e.target.value }))}
                        />
                    </>
                ) : null}
            </Modal>
        </div>
    );
});
